<template>
  <div class="vg_wrapper">
    <el-card>
      <!--      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getNow()" label-width="120px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料采购号:">
                <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写材料采购号"></el-input>
              </el-form-item>
            </el-col>
            &lt;!&ndash; <el-col :md="8">
							<el-form-item label="合同客户货号:">
								<el-input size="small" v-model.trim="searchForm.cust_artno" clearable placeholder="请填写合同内客户货号"></el-input>
							</el-form-item>
						</el-col> &ndash;&gt;
            &lt;!&ndash;						<el-col :md="8">&ndash;&gt;
            &lt;!&ndash;							<el-form-item label="订单来源:">&ndash;&gt;
            &lt;!&ndash;								<el-select v-model="searchForm.puca_source" filterable placeholder="请选择订单来源" clearable size="small">&ndash;&gt;
            &lt;!&ndash;									<el-option&ndash;&gt;
            &lt;!&ndash;											v-for="item in sourceList"&ndash;&gt;
            &lt;!&ndash;											:key="item.value"&ndash;&gt;
            &lt;!&ndash;											:label="item.label"&ndash;&gt;
            &lt;!&ndash;											:value="item.value">&ndash;&gt;
            &lt;!&ndash;									</el-option>&ndash;&gt;
            &lt;!&ndash;								</el-select>&ndash;&gt;
            &lt;!&ndash;							</el-form-item>&ndash;&gt;
            &lt;!&ndash;						</el-col>&ndash;&gt;
            &lt;!&ndash;					</el-row>&ndash;&gt;
            &lt;!&ndash;					<el-row>&ndash;&gt;
            <el-col :md="8">
              <el-form-item label="供应商简称:">
                <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="是否变更:">
                <el-radio v-model="searchForm.isch_status" label="1" @input="getPucas">是</el-radio>
                <el-radio v-model="searchForm.isch_status" label="0" @input="getPucas">否</el-radio>
              </el-form-item>
            </el-col>
            &lt;!&ndash;						<el-col :md="8">&ndash;&gt;
            &lt;!&ndash;							<el-form-item label="采购经办人:">&ndash;&gt;
            &lt;!&ndash;								<el-input size="small" v-model.trim="searchForm.puca_stff_name" clearable placeholder="请填写采购经办人"></el-input>&ndash;&gt;
            &lt;!&ndash;							</el-form-item>&ndash;&gt;
            &lt;!&ndash;						</el-col>&ndash;&gt;
          </el-row>
          <el-row>
            &lt;!&ndash; <el-col :md="8">
              <el-form-item label="供应商简称:">
                <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商简称"></el-input>
              </el-form-item>
            </el-col> &ndash;&gt;
            <el-col :md="8">
              <el-form-item label="下发状态:">
                <el-select v-model="searchForm.puca_status" filterable placeholder="请选择下发状态" clearable size="small">
                  <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="合同日期:">
                <el-date-picker
                  size="small"
                  v-model="timeValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <el-form-item label="要求交货日期:">
                <el-date-picker
                  size="small"
                  v-model="timeValue1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" class="vg_ml_16" @click="getPucas()">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>-->
      <div class="vd_button_group vg_mb_8">
        <el-button size="small" type="success" icon="el-icon-document-add" v-if="userInfo.acct_id === 1" @click="showImportExcelDialog()"
          >导入</el-button
        >
        <el-button size="small" type="warning" icon="el-icon-connection" v-if="userInfo.acct_id === 1" @click="synchronizationERP()"
          >同步ERP</el-button
        >
        <el-button size="small" type="info" icon="el-icon-refresh-right" class="vd_export" @click="buttonRefresh()">刷新</el-button>
        <!--<el-button size="small" type="warning" icon="el-icon-s-claim" @click="issue()">下发</el-button>-->
        <!--<el-button size="small" type="danger" icon="el-icon-info" @click="makeInvalidOrder()">失效</el-button> -->
      </div>
      <el-row>
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="'puca_id'"
          :columns="tableProperties"
          v-loading="loadingFlag"
          :totalPage="totalPage"
          :need-fixed-height="true"
          :pageSize="50"
          @selection-change="handleSelectionChange"
          @row-dblclick="dbClickJp"
          @getTableData="getPucas"
        >
          <template v-slot:chan_num="scope">
            <span v-if="scope.row.chan_num > 0 && scope.row.chan_status === 1">
              <el-badge :is-dot="scope.row.chan_status === 1" class="item">
                <span>{{ scope.row.chan_num }}</span>
              </el-badge>
            </span>
            <span v-else-if="scope.row.chan_num > 0 && scope.row.chan_status === 0">
              <span style="color: #000; font-weight: bold">{{ scope.row.chan_num }}</span>
            </span>
            <span v-else>
              <span style="color: #0e932e">{{ scope.row.chan_num }}</span>
            </span>
          </template>
          <template v-slot:shipment_status="scope">
            <el-tag v-if="scope.row.shipment_status === 0" type="info" size="small">未发货</el-tag>
            <el-tag v-else-if="scope.row.shipment_status === 1" type="warning" size="small">部分发货</el-tag>
            <el-tag v-else-if="scope.row.shipment_status === 2" size="small">已发货</el-tag>
            <el-tag v-else-if="scope.row.shipment_status === 3" type="success" size="small">已完结</el-tag>
            <el-tag v-else-if="scope.row.shipment_status === 4" type="danger" size="small">已失效</el-tag>
          </template>
          <template v-slot:puca_status="scope">
            <el-tag v-if="scope.row.puca_status === 0" type="info" size="small">未下发</el-tag>
            <el-tag v-else-if="scope.row.puca_status === 1" type="warning" size="small">部分失效</el-tag>
            <el-tag v-else-if="scope.row.puca_status === 2" size="small">已下发</el-tag>
            <el-tag v-else-if="scope.row.puca_status === 3" type="success" size="small">已完结</el-tag>
            <el-tag v-else-if="scope.row.puca_status === 4" type="danger" size="small">已失效</el-tag>
          </template>
          <template v-slot:pdf_status="scope">
            <span v-if="scope.row.pdf_status === 0" class="vd_cd81e06">未打印</span>
            <span v-if="scope.row.pdf_status === 1" class="vd_c4dbf2a">已打印</span>
          </template>
          <template v-slot:dent_total="scope">
            <el-link v-if="scope.row.dent_total" class="vg_cursor" type="primary" @click="relationDelivery(scope.row)"
              >共{{ scope.row.dent_total }}单</el-link
            >
            <span v-else class="vg_9f9a9a">暂无</span>
          </template>
        </search-table>
        <!--        <el-col :md="24">
          <el-table
            class="vg_cursor"
            style="width: 100%"
            stripe
            ref="multiTable"
            v-loading="loadingFlag"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
            @row-dblclick="dbClickJp"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="材料采购号" prop="modr_no" min-width="80" show-overflow-tooltip />
            <el-table-column label="供应商简称" prop="supp_name" min-width="80" show-overflow-tooltip />
            <el-table-column label="变更次数" prop="chan_num" align="center" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.chan_num > 0 && scope.row.chan_status === 1">
                  <el-badge :is-dot="scope.row.chan_status === 1" class="item">
                    <span>{{ scope.row.chan_num }}</span>
                  </el-badge>
                </span>
                <span v-else-if="scope.row.chan_num > 0 && scope.row.chan_status === 0">
                  <span style="color: #000; font-weight: bold">{{ scope.row.chan_num }}</span>
                </span>
                <span v-else>
                  <span style="color: #0e932e">{{ scope.row.chan_num }}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="合同总金额" prop="puca_total" min-width="50" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.puca_total">
                  {{ scope.row.puca_total | formatPrice }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="经办人" prop="puca_stff_name"></el-table-column>
            <el-table-column label="交期备注" prop="cust_abbr" min-width="60" v-if="userInfo.cptt_type !== 5">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">{{ scope.row.cust_abbr }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="客户简称" prop="cust_abbr" min-width="60">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">{{ scope.row.cust_abbr }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="合同日期" prop="puca_date" align="center" min-width="60">
              <template v-slot="scope">
                <span>{{ scope.row.puca_date | formatDate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="要求交货日期" prop="deli_date" align="center" min-width="60">
              <template v-slot="scope">
                <span>{{ scope.row.deli_date | formatDate }}</span>
              </template>
            </el-table-column>

            &lt;!&ndash;						<el-table-column label="采购经办人" prop="puca_stff_name" min-width="50" align="center"/>&ndash;&gt;

            &lt;!&ndash; <el-table-column label="采购经办人" prop="puca_stff_name" min-width="50" align="center">
							<template slot-scope="scope">
                <span v-if="scope.row.puca_stff_name">
                    {{ scope.row.puca_stff_name}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> &ndash;&gt;
            <el-table-column label="下发状态" prop="puca_status" min-width="50" align="center" v-if="userInfo.cptt_type !== 5">
              <template v-slot="scope">
                <el-tag v-if="scope.row.puca_status === 0" type="info" size="small">未下发</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 1" type="warning" size="small">部分失效</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 2" size="small">已下发</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 3" type="success" size="small">已完结</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 4" type="danger" size="small">已失效</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="打印状态" prop="puca_status" min-width="50" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.pdf_status === 0" class="vd_cd81e06">未打印</span>
                <span v-if="scope.row.pdf_status === 1" class="vd_c4dbf2a">已打印</span>
              </template>
            </el-table-column>
            <el-table-column label="关联送货单" align="center">
              <template v-slot="scope">
                <el-link v-if="scope.row.dent_total" class="vg_cursor" type="primary" @click="relationDelivery(scope.row)"
                  >共{{ scope.row.dent_total }}单</el-link
                >
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            &lt;!&ndash;						<el-table-column label="快递物流单号" prop="locs_no" min-width="80" align="center">&ndash;&gt;
            &lt;!&ndash;							<template v-slot="scope">&ndash;&gt;
            &lt;!&ndash;								<el-link class="vg_cursor" @click="logDetail()" v-if="scope.row.locs_no">&ndash;&gt;
            &lt;!&ndash;									<div class="vg_hover_icon">&ndash;&gt;
            &lt;!&ndash;										<span style="margin-left:15px">{{scope.row.locs_no}}</span>&ndash;&gt;
            &lt;!&ndash;									</div>&ndash;&gt;
            &lt;!&ndash;								</el-link>&ndash;&gt;
            &lt;!&ndash;								<span v-else-if="scope.row.driv_phone" style="color:#e01515;font-size: 22px">/</span>&ndash;&gt;
            &lt;!&ndash;								<span v-else style="color:#ccc"><i class="el-icon-time"/> 暂无</span>&ndash;&gt;
            &lt;!&ndash;							</template>&ndash;&gt;
            &lt;!&ndash;						</el-table-column>&ndash;&gt;
            &lt;!&ndash;						<el-table-column label="自运司机联系方式" prop="driv_phone" align="center">&ndash;&gt;
            &lt;!&ndash;							<template v-slot="scope">&ndash;&gt;
            &lt;!&ndash;								<div v-if="scope.row.driv_phone">&ndash;&gt;
            &lt;!&ndash;									<el-row>&ndash;&gt;
            &lt;!&ndash;										<el-col>&ndash;&gt;
            &lt;!&ndash;											<span><i class="el-icon-user-solid"/></span>&ndash;&gt;
            &lt;!&ndash;											<span> {{scope.row.driv_name}} </span>&ndash;&gt;
            &lt;!&ndash;										</el-col>&ndash;&gt;
            &lt;!&ndash;										<el-col>&ndash;&gt;
            &lt;!&ndash;											<span><i class="el-icon-phone-outline"/></span>&ndash;&gt;
            &lt;!&ndash;											<span> {{scope.row.driv_phone}}</span>&ndash;&gt;
            &lt;!&ndash;										</el-col>&ndash;&gt;
            &lt;!&ndash;									</el-row>&ndash;&gt;
            &lt;!&ndash;								</div>&ndash;&gt;
            &lt;!&ndash;								<span v-else-if="scope.row.locs_no" style="color:#e01515;font-size: 22px">/</span>&ndash;&gt;
            &lt;!&ndash;								<span v-else style="color:#ccc"><i class="el-icon-time"/> 暂无</span>&ndash;&gt;
            &lt;!&ndash;							</template>&ndash;&gt;
            &lt;!&ndash;						</el-table-column>&ndash;&gt;
            &lt;!&ndash;						<el-table-column label="操作" align="center">&ndash;&gt;
            &lt;!&ndash;							<template v-slot="scope">&ndash;&gt;
            &lt;!&ndash;								&lt;!&ndash;feature： 仅限补货单已下发的可以变更 now： 补货单/送货单 已下发的可以变更 记录变更次数，送货单变更未来只能通过ERP开变更单并记录变更次数不管已下发还是未下发&ndash;&gt;&ndash;&gt;
            &lt;!&ndash;								<el-button :disabled="!(scope.row.puca_source === 0 && scope.row.puca_status === 1 || scope.row.puca_source === 1 && scope.row.puca_status === 1) "&ndash;&gt;
            &lt;!&ndash;								           plain size="mini" type="warning" @click="changeNum(scope.row)">变更</el-button>&ndash;&gt;
            &lt;!&ndash;								&lt;!&ndash;feature： 仅限送货单已下发补货&ndash;&gt;&ndash;&gt;
            &lt;!&ndash;								<el-button :disabled="!(scope.row.puca_status === 1 && scope.row.puca_source ===1)" plain size="mini" type="success" @click="reissue(scope.row)">补发</el-button>&ndash;&gt;
            &lt;!&ndash;							</template>&ndash;&gt;
            &lt;!&ndash;						</el-table-column>&ndash;&gt;

            &lt;!&ndash; <el-table-column label="快递物流单号" prop="locs_no" min-width="80" align="center">
							<template v-slot="scope">
								<el-link class="vg_cursor" @click="logDetail()" v-if="scope.row.locs_no">
									<div class="vg_hover_icon">
										<span style="margin-left:15px">{{scope.row.locs_no}}</span>
									</div>
								</el-link>
								<span v-else-if="scope.row.driv_phone" style="color:#e01515;font-size: 22px">/</span>
								<span v-else style="color:#ccc"><i class="el-icon-time"/> 暂无</span>
							</template>
						</el-table-column>
						<el-table-column label="自运司机联系方式" prop="driv_phone" align="center">
							<template v-slot="scope">
								<div v-if="scope.row.driv_phone">
									<el-row>
										<el-col>
											<span><i class="el-icon-user-solid"/></span>
											<span> {{scope.row.driv_name}} </span>
										</el-col>
										<el-col>
											<span><i class="el-icon-phone-outline"/></span>
											<span> {{scope.row.driv_phone}}</span>
										</el-col>
									</el-row>
								</div>
								<span v-else-if="scope.row.locs_no" style="color:#e01515;font-size: 22px">/</span>
								<span v-else style="color:#ccc"><i class="el-icon-time"/> 暂无</span>
							</template>
						</el-table-column> &ndash;&gt;
            &lt;!&ndash; <el-table-column label="操作" align="center">
							<template v-slot="scope"> &ndash;&gt;
            &lt;!&ndash;feature： 仅限补货单已下发的可以变更 now： 补货单/送货单 已下发的可以变更 记录变更次数，送货单变更未来只能通过ERP开变更单并记录变更次数不管已下发还是未下发&ndash;&gt;
            &lt;!&ndash; <el-button :disabled="!(scope.row.puca_source === 0 && scope.row.puca_status === 1 || scope.row.puca_source === 1 && scope.row.puca_status === 1 || scope.row.puca_status === 0) "
								           plain size="mini" type="warning" @click="changeNum(scope.row)">变更</el-button> &ndash;&gt;
            &lt;!&ndash;feature： 仅限送货单已下发补货&ndash;&gt;
            &lt;!&ndash; <el-button :disabled="!(scope.row.puca_status === 1 && scope.row.puca_source ===1)" plain size="mini" type="success" @click="reissue(scope.row)">补发</el-button>
							</template>
						</el-table-column> &ndash;&gt;
          </el-table>
        </el-col>-->
      </el-row>
      <el-row>
        <!--        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination" />
        </el-col>-->
      </el-row>
      <!--下发提示框-->
      <el-dialog :visible.sync="issueDialogVisible" top="45vh" center width="15%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否确认下发</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="issueDialogVisible = false">否</el-button>
          <el-button type="primary" size="mini" @click="confirmIssue()">是</el-button>
        </span>
      </el-dialog>
      <!--失效提示框-->
      <el-dialog :visible.sync="invalidDialogVisible" top="45vh" center width="15%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否对此订单失效</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="invalidDialogVisible = false">否</el-button>
          <el-button type="primary" size="mini" @click="confirmMakeInvalidOrder()">是</el-button>
        </span>
      </el-dialog>
      <!--变更提示框-->
      <el-dialog :visible.sync="changeNumDialogVisible" top="45vh" center width="15%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否已和外销确认数量</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="changeNumDialogVisible = false">尚未</el-button>
          <el-button type="primary" size="mini" @click="confirmChangeNum()">确认</el-button>
        </span>
      </el-dialog>
      <!--补发提示框-->
      <el-dialog :visible.sync="reissueDialogVisible" top="45vh" center width="15%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否已和供应商确认数量</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="reissueDialogVisible = false">尚未</el-button>
          <el-button type="primary" size="mini" @click="confirmReissue()">确认</el-button>
        </span>
      </el-dialog>
      <!--补发提示框-->
      <el-dialog :visible.sync="importExcelDialogVisible" top="30vh" center @close="fileList = []" width="50%">
        <span slot="title" style="font-size: 20px">导入Excel文件数据</span>
        <div style="text-align: center; margin-bottom: 32px">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :on-preview="handlePreview"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            accept=".xls, .xlsx"
            :auto-upload="false"
            :file-list="fileList"
            :on-remove="removeFile"
            :on-change="selectFile"
            :before-upload="beforeUpload"
          >
            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件!</div>
            <el-button slot="trigger" size="small" type="primary" icon="el-icon-document-add">选择文件</el-button>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer vg_mb_16">
          <el-button type="danger" size="mini" @click="cancelUpload">取消</el-button>
          <el-button type="primary" size="mini" @click="addImport()">导入</el-button>
        </span>
      </el-dialog>
      <!-- 关联送货单 -->
      <el-dialog :visible.sync="relationDeliveryFlag" top="30vh" center width="50%">
        <el-row class="vd_dis_cen vg_mb_16"> {{ relationDeliveryName }}关联送货编号 </el-row>
        <el-row class="vd_dis_cen vg_mb_16">
          <el-table border :data="relationDeliveryList" style="width: 100%">
            <el-table-column label="序号" width="80" align="center">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="dent_no" align="center" label="送货编号">
              <template v-slot="scope">
                <el-link @click="jumpDentDetail(scope.row)" type="primary" class="vg_cursor">{{ scope.row.dent_no }}</el-link>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="supp_babbr" label="收货方"> </el-table-column>
          </el-table>
        </el-row>
        <el-row class="vd_dis_right">共{{ relationDeliveryList.length }}单</el-row>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import { matrAPI } from '@api/modules/matr';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';

export default {
  name: 'matrList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { label: '材料采购号', prop: 'modr_no', itemType: 'input', input: true, sortable: true, widthAuto: true },
        { label: '供应商简称', prop: 'supp_name', itemType: 'input', input: true, sortable: true, widthAuto: true },
        { label: '变更次数', prop: 'chan_num', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '合同总金额', prop: 'puca_total', itemType: 'input', input: false, sortable: true, widthAuto: true },
        { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: true, labelWidth: '150px' },
        {
          label: '合同日期',
          prop: 'puca_date',
          itemType: 'date',
          input: true,
          sortable: true,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          label: '要求交货日期',
          prop: 'deli_date',
          itemType: 'date',
          input: true,
          sortable: true,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        { label: '经办人', prop: 'puca_stff_name', itemType: 'input', sortable: false, widthAuto: true },
        {
          label: '下发状态',
          prop: 'puca_status',
          itemType: 'select',
          options: [
            { value: '0', label: '未下发' },
            { value: '1', label: '部分失效' },
            { value: '2', label: '已下发' },
            { value: '3', label: '已完结' },
            { value: '4', label: '已失效' }
          ],
          sortable: false,
          widthAuto: true,
          columnShow: this.$cookies.get('userInfo').cptt_type !== 5
        },
        {
          label: '打印状态',
          prop: 'pdf_status',
          itemType: 'select',
          options: [
            { value: 0, label: '未打印' },
            { value: 1, label: '已打印' }
          ],
          sortable: false,
          widthAuto: true
        },
        /*        {
          label: '发货状态',
          prop: 'shipment_status',
          itemType: 'select',
          options: [
            { value: 0, label: '未发货' },
            { value: 1, label: '部分发货' },
            { value: 2, label: '已发货' },
            { value: 3, label: '已完结' }
          ],
          sortable: false,
          widthAuto: true
        },*/
        { label: '关联送货单', prop: 'dent_total', itemType: 'input', input: false, sortable: false, widthAuto: true, fixed: 'right' }
      ],
      fileList: [],
      file: null,
      issueDialogVisible: false,
      invalidDialogVisible: false,
      changeNumDialogVisible: false,
      reissueDialogVisible: false,
      importExcelDialogVisible: false,
      searchForm: {
        page_no: 1
      },
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loadingFlag: true,
      timeValue: [],
      timeValue1: [],
      statusList: [
        {
          value: '0',
          label: '未下发'
        },
        {
          value: '1',
          label: '部分失效'
        },
        {
          value: '2',
          label: '已下发'
        },
        {
          value: '3',
          label: '已完结'
        },
        {
          value: '4',
          label: '已失效'
        }
      ],
      sourceList: [
        {
          value: '0',
          label: '补货单'
        },
        {
          value: '1',
          label: '送货单'
        }
      ],
      userInfo: {},
      relationDeliveryFlag: false,
      relationDeliveryName: '',
      relationDeliveryList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 91);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.userInfo = this.$cookies.get('userInfo');
    if (this.$route.query.modr_no) {
      this.$refs.multiTable.searchForm.modr_no = this.$route.query.modr_no;
    }
    this.tableProperties = this.tableProperties.filter(x => x.columnShow !== false);
    this.initData();
  },
  watch: {},
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toTimeDay(row);
    },
    // 时间转换
    formatPrice(row) {
      return helper.reservedThree(row);
    }
  },
  methods: {
    initData() {
      this.getPucas();
    },
    // 获取材料采购合同list
    getPucas() {
      this.loadingFlag = true;
      getNoCatch(matrAPI.getPucas, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadingFlag = false;
        }, 500);
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getPucas();
    },
    // 查询方法
    getNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.initData();
    },
    // 打开关联送货单弹框
    relationDelivery(row) {
      get(matrAPI.getDentByPucaId, { puca_id: row.puca_id }).then(res => {
        if (res.data.code === 0) {
          this.relationDeliveryName = row.modr_no;
          this.relationDeliveryList = res.data.data.form;
          this.relationDeliveryFlag = true;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    //跳转对应的送货详情
    jumpDentDetail(row) {
      this.relationDeliveryFlag = false;
      this.jump('/dent_edit', { perm_id: 142, form_id: row.dent_id, autoFlag: 0 });
    },
    // 同步erp
    synchronizationERP() {
      post(matrAPI.syncPucaByErp).then(({ data }) => {
        if (data.code === 0) {
          this.$message.success('同步成功!');
        } else {
          let mg = data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 下发功能
    issue() {
      if (this.multiSelection.length === 0) {
        this.$message.warning('请至少选择一条数据!');
        return;
      }
      let issueFlag = this.multiSelection.filter(item => {
        return item.puca_status === 3;
      });
      if (issueFlag.length > 0) return this.$message.warning('已失效的不可下发');
      this.issueDialogVisible = true;
    },
    confirmIssue() {
      let idsArr = this.multiSelection.map(({ puca_id }) => Object.values({ puca_id }).toString());
      // 批量下发
      post(matrAPI.addDent, { puca_id_list: idsArr }).then(({ data }) => {
        if (data.code === 0) {
          this.$message.success('下发成功!');
          this.getPucas();
        }
      });
      this.issueDialogVisible = false;
    },
    //获取明细功能
    getPucaByIds() {
      if (this.multiSelection.length <= 0) {
        this.$message({ message: '请至少选择一条数据', type: 'error' });
        return;
      }
      let reg = new RegExp(/[^a-zA-Z]/g);
      let modrNoSet = new Set();
      let podrNoSet = new Set();
      this.multiSelection.forEach(item => {
        let modrNo = item.modr_no.replaceAll(reg, '');
        let podrNo = item.podr_no.replaceAll(reg, '');
        if (modrNo) modrNoSet.add(modrNo);
        if (podrNo) podrNoSet.add(podrNo);
      });
      if (modrNoSet.size > 1) {
        this.$message({ message: '请选择相同前缀的材料采购合同号', type: 'error' });
        return;
      }
      if (podrNoSet.size > 1) {
        this.$message({ message: '请选择相同前缀的成品采购合同号', type: 'error' });
        return;
      }
      let ids = this.multiSelection.map(({ puca_id }) => Object.values({ puca_id }).toString()).join(',');
      const permId = this.$route.query.perm_id;
      this.jump('/puca_edit', { perm_id: permId, pucaIds: ids, batch: true });
    },
    removeFile() {
      // 单选暂定清空
      this.fileList = [];
      this.file = null;
    },
    selectFile(file, fileList) {
      this.fileList = fileList;
      this.file = file.raw;
    },
    cancelUpload() {
      this.importExcelDialogVisible = false;
      this.fileList = [];
    },
    // 新增导入---feature 删除 直接跳编辑
    addImport() {
      //上传内容
      let formData = new FormData();
      formData.append('excelFileList', this.file);
      post(matrAPI.import_excel, formData).then(({ data }) => {
        if (data.code === 0) {
          this.getPucas();
        }
      });
      this.importExcelDialogVisible = false;
    },
    // 打印上传内容
    handlePreview(file) {},
    // 回复上传内容
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 限制上传前文件为.xls, .xlsx
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['xls', 'xlsx'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是xls、xlsx格式');
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 5MB');
        return false;
      }
    },

    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/puca_edit', { perm_id: permId, form_id: row.puca_id });
      post(matrAPI.editPucaChanStatus, { puca_id: row.puca_id, chan_status: 0 }).then(({ data }) => {
        if (data.code === 0) {
          this.getPucas();
        }
      });
    },
    // 图片类型
    formatPic(scope, type) {
      let updateTime = this.imgUpdatatime;
      return this.helper.picUrl(scope.row.imge_url, type, updateTime, scope.row.imge_source);
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 查询快递物流单号详情
    logDetail() {},

    // 变更订单数量，详情只有点了变更才有变更一栏
    changeNum(row) {
      this.row = row;
      this.changeNumDialogVisible = true;
    },
    confirmChangeNum() {
      this.changeNumDialogVisible = false;
      const permId = this.$route.query.perm_id;
      this.jump('/puca_edit', { perm_id: permId, form_id: this.row.puca_id, type: this.row.puca_source, isChange: 'change' });
    },
    // 补货单新增选择送货单,除了数量和变更数量（栏目没有），补发的一旦保存即可生效
    reissue(row) {
      this.row = row;
      this.reissueDialogVisible = true;
    },
    // 补货单新增选择送货单,除了数量和变更数量（栏目没有），补发的一旦保存即可生效
    confirmReissue() {
      this.reissueDialogVisible = false;
      if (this.row.puca_source === 1) {
        const permId = this.$route.query.perm_id;
        this.jump('/puca_add', { perm_id: permId, form_id: this.row.puca_id, type: this.row.puca_source, isReissue: 'reissue' });
      } else {
        console.log('错误');
      }
    },
    makeInvalidOrder() {
      if (this.multiSelection.length === 0) {
        this.$message.warning('请至少选择一条数据!');
        return;
      }
      this.invalidDialogVisible = true;
    },
    confirmMakeInvalidOrder() {
      let idsArr = this.multiSelection.map(({ puca_id }) => Object.values({ puca_id }).toString());
      // 批量下发
      post(matrAPI.puca_invalid_by_ids, { puca_id_list: idsArr }).then(({ data }) => {
        if (data.code === 0) {
          this.$message.success('已失效!');
          this.getPucas();
        }
      });
      this.invalidDialogVisible = false;
    },
    showImportExcelDialog() {
      this.importExcelDialogVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button_group {
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img {
  margin-top: 5px;
  width: 40px;
}
.vd_notpass {
  color: rgb(187, 187, 187);
}
.vd_pass {
  color: #188dff;
}
.vd_complate {
  color: #36ab60;
}
.vd_disable {
  color: #d81e06;
}
.vd_wei {
  color: #ff8511;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.vg_hover_icon:hover {
  background-image: url('~@/assets/image/wl07.png');
  background-repeat: no-repeat;
  left: -10px;
  background-position: center left 0%;
  background-size: 12px 12px;
}
.el-icon-user-solid {
  color: #34b7f1;
  font-size: 12px;
}
.el-icon-phone-outline {
  color: #67c23a;
  font-size: 12px;
}
.upload-demo {
  margin-right: 10px;
  ::v-deep.el-upload--text {
    border: none;
    width: auto;
    height: 100%;
    border-radius: 0;
  }
  ::v-deep .el-upload-list .el-upload-list--text {
    display: none;
  }
  ::v-deep .el-list-leave-active {
    transition: none;
  }
  ::v-deep .el-list-leave-to {
    transition: none;
  }
}
.item {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #d05757;
  cursor: pointer;
}
.vd_dis_cen {
  display: flex;
  justify-content: center;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
.vd_cd81e06 {
  color: #d81e06;
}
.vd_c4dbf2a {
  color: #4dbf2a;
}
</style>
